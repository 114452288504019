import React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Box,
  Stack,
  Paper,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
} from "@mui/material/";
import { styled } from "@mui/material/styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Iko from "../../assets/Iko.jpeg";
import "./MyJourney.css";

function MyJourney() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#f7f6f2",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#000000",
  }));

  return (
    <div>
      <Helmet>
          <title>Jacqueline Marie Healing</title>
          <meta name="I have been studying and practicing personal enlightenment and healing modalities for over 30 years. 
          What began as a personal endeavor has now expanded into a deep passion for sharing what I have learned with others 
          to make life joyful - as it is supposed to be!"
          content="About Me" />
      </Helmet>
      <Typography
        className="journey-quote-text"
        variant="inherit"
        align="center"
        gutterBottom
        paragraph
      >
        <p>
          <i>
            “Happiness is your nature. It is not wrong to desire it. What is
            wrong is seeking it outside when it is inside.” <br /> - Ramana
            Maharshi
          </i>
        </p>
      </Typography>
      <Divider variant="middle" />
      <Typography
        className="journey-text"
        variant="inherit"
        align="center"
        gutterBottom
        paragraph
      >
        <p>
          I have been studying and practicing personal enlightenment and healing
          modalities for over 30 years. What began as a personal endeavor has
          now expanded into a deep passion for sharing what I have learned with
          others to make life joyful - as it is supposed to be! <br /> <br />
          Here are some of the highlights of my journey.
        </p>

        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Item>
              <List>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Studied Business/Accounting - University of Wisconsin Milwaukee & Cardinal Stritch College" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Career in accounting, business, and human resources" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Transcendental Meditation practitioner with Dev Andrea La Fave 1995" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Attuned to Usui Shiki Ryoko Reiki I and Reiki II by Linda Lohr - International Center for Reiki 2004" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Living on Live Food Chef certification - with Alissa Cohen Raw Vegan 2011" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Developing Consciousness Certificate program Module 1 - Free Spirit School 2013" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Sacred Childbirth with Reiki certification - Rev. Cori Nielsen 2013" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Reiki Master certification - Calleen Bohl, Loving Light Healing 2013" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Crystal Healing - Diane Bloom, Free Spirit Crystals 2013-2014" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Workshop with DaEl Walker, Crystal Awareness Institute, Sunol CA - Free Spirit Crystals 2015" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Reiki Master certification - Cheri Knuth, Unified Body Wellness Institute 2015" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Reiki Master Teacher certification - Calleen Bohl, Loving Light Healing 2015" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Advanced Channeling - CC Treadway, Treadway Esoteric 2016" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Self-Sovereignty - CC Treadway, Treadway Esoteric 2017" />
                  </ListItem>
                </div>
                <div className="journey-bulleted-list">
                  <ListItemIcon>
                    <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItem disablePadding>
                    <ListItemText primary="Treadway Esoteric Light School Practitioner certification - CC Treadway 2018" />
                  </ListItem>
                </div>
              </List>
            </Item>
          </Stack>
        </Box>
      </Typography>

      <img className="journey-image" src={Iko} alt="Iko" />

      <div className="journey-bottom-padding">
        <a href="/FAQ's" className="journey-link-text">
          Click here to learn more on my FAQ's page
        </a>
      </div>
    </div>
  );
}

export default MyJourney;
