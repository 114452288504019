import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, Divider, Menu } from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/BigLogo.png";
import "../../App.css";
import "./ResponsiveAppBar.css";

function ResponsiveAppBar() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 870);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 870);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isDesktop ? (
        <>
          <img
            className="app-bar-logo"
            src={logo}
            alt="Jacqueline Marie Healing"
          />
          <p className="app-bar-title-text">
            REIKI / MEDITATION / WORKING WITH CHILDREN
          </p>
          <nav className="app-bar-nav-bar">
            <Divider role="presentation">
              <Box className="app-bar-box">
                <Button size="large" color="secondary" href="/">
                  Welcome
                </Button>
                <Button size="large" color="secondary" href="MyJourney">
                  My Journey
                </Button>
                <Button size="large" color="secondary" href="Services">
                  Services
                </Button>
                <Button size="large" color="secondary" href="ReikiTraining">
                  Reiki Training
                </Button>
                <Button size="large" color="secondary" href="FAQ's">
                  FAQ's
                </Button>
                <Button size="large" color="secondary" href="ReachOut">
                  Reach Out
                </Button>
              </Box>
            </Divider>
          </nav>
        </>
      ) : (
        <div>
          <img
            className="app-bar-logo-mobile"
            src={logo}
            alt="Jacqueline Marie Healing"
          />
          <p className="app-bar-title-text-mobile">
            REIKI / MEDITATION / WORKING WITH CHILDREN
          </p>
          <nav className="app-bar-nav-bar">
            <Divider role="presentation">
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <div className="menu-button">
                  Menu
                  <MenuIcon fontSize="small"></MenuIcon>
                </div>
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "top",
                }}
              >
                <box className="open-menu">
                  <Button color="secondary" href="/">
                    Welcome
                  </Button>
                  <Button color="secondary" href="MyJourney">
                    My Journey
                  </Button>
                  <Button color="secondary" href="Services">
                    Services
                  </Button>
                  <Button color="secondary" href="ReikiTraining">
                    Reiki Training
                  </Button>
                  <Button color="secondary" href="FAQ's">
                    FAQ's
                  </Button>
                  <Button color="secondary" href="ReachOut">
                    Reach Out
                  </Button>
                </box>
              </Menu>
            </Divider>
          </nav>
        </div>
      )}
    </div>
  );
}
export default ResponsiveAppBar;
