import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Typography, Link, Divider } from "@mui/material/";
import Chakra from "../../assets/Chakra.jpeg";
import "./FAQ.css";

function TextAsLink() {
  var link = (
    <Link
      href="https://chopra.com/articles/how-meditation-can-help-anxiety#sm.00001ev0vhl7p3en6xqti8zwwpw50"
      underline="none"
      className="faq-link-text"
    >
      {"Click here"}
    </Link>
  );

  return (
    <p className="services-link-sentance">
      {link}&nbsp;for more information from Deepak Chopra.
    </p>
  );
}

function FAQ() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 870);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 870);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="Energy healing is a powerful form of healing that is quickly becoming relevant in today's world. 
          Contemporary science is just beginning to study and prove the validity of energy healing - 
          the link between your thoughts and emotions to your physical health."
          content="Frequently Asked Questions"
        />
      </Helmet>
      {isDesktop ? (
        <div className="faq-master-div">
          <div className="faq-div-css">
            <div className="faq-grow">
              <Typography variant="h6" className="faq-text">
                <b>What is energy healing? </b>
              </Typography>
            </div>
            <div className="faq-shrink">
              <Typography className="faq-text">
                <p>
                  Energy healing is a powerful form of healing that is quickly
                  becoming relevant in today's world. Contemporary science is
                  just beginning to study and prove the validity of energy
                  healing - the link between your thoughts and emotions to your
                  physical health. Our world is nothing more than energy in
                  varying degrees. Trauma, emotional and mental stress, false
                  belief systems, physical distress, and other blocks to our
                  personal growth can be stored in our bodies&#39; energy
                  fields, impacting our ability to function at our full
                  potential. Energetic healing facilitates the healing process
                  by clearing these blocks in our energy fields, repairing and
                  rebalancing the energy so the body can access its inherent
                  ability to heal itself.
                </p>
              </Typography>
            </div>
          </div>
          <Divider variant="middle" />
          <div className="faq-div-css">
            <div className="faq-grow">
              <Typography variant="h6" className="faq-text">
                <b>How does energy healing work? </b>
              </Typography>
            </div>
            <div className="faq-shrink">
              <Typography className="faq-text">
                <p>
                  The human body is surrounded internally and externally by an
                  electromagnetic energy field. This energy field flows through
                  and permeates all living things - this is something well known
                  in science and physics. In eastern medicine, it has long been
                  understood that critical information is held within the energy
                  field relating to a person's health. From an energy healer's
                  standpoint, all health issues are interconnected through three
                  realities: mind, body, and spirit. These three levels all work
                  together and influence each other for your health and
                  vitality. When in harmony they will operate in health - but,
                  they will create disease when out of sync.
                </p>
              </Typography>
            </div>
          </div>
          <Divider variant="middle" />
          <div className="faq-div-css">
            <div className="faq-grow">
              <Typography variant="h6" className="faq-text">
                <b>How can meditation help me? </b>
              </Typography>
            </div>
            <div className="faq-shrink">
              <Typography className="faq-text">
                <p>
                  Those who want to meditate can choose among a wide range of
                  practices. What they have in common is a narrowing of focus
                  that shuts out the external world and stills the body. There
                  are numerous studies done on the benefits of meditation. In a
                  study involving Charles L. Raison, MD, clinical director of
                  the Mind-Body Program at Emory University School of Medicine
                  in Atlanta, meditation was found to improve both physical and
                  emotional responses to stress. In the study, people who
                  meditated regularly for six weeks showed less activation of
                  their immune systems and less emotional distress when they
                  were put in a stressful situation. Stress reduction could be
                  the key to mediation's beneficial effect on health. More
                  studies have shown improvement for a host of maladies,
                  including fibromyalgia and psoriasis in patients who meditate.
                  Science hasn't yet connected all the dots between the
                  meditating brain and the immune system. But a University of
                  Wisconsin study saw increased electrical activity in regions
                  of the left frontal lobe after eight weeks of training in
                  meditation. This area tends to be more active in optimistic
                  people.
                </p>
              </Typography>
            </div>
          </div>
          <TextAsLink />
          <Divider variant="middle" />
          <img className="faq-image" src={Chakra} alt="7 Chakras" />
        </div>
      ) : (
        <div className="faq-div-mobile">
          <Typography variant="h6" className="faq-text-mobile">
            <b>What is energy healing? </b>
          </Typography>
          <Typography className="faq-text-mobile">
            <p>
              Energy healing is a powerful form of healing that is quickly
              becoming relevant in today's world. Contemporary science is just
              beginning to study and prove the validity of energy healing - the
              link between your thoughts and emotions to your physical health.
              Our world is nothing more than energy in varying degrees. Trauma,
              emotional and mental stress, false belief systems, physical
              distress, and other blocks to our personal growth can be stored in
              our bodies&#39; energy fields, impacting our ability to function
              at our full potential. Energetic healing facilitates the healing
              process by clearing these blocks in our energy fields, repairing
              and rebalancing the energy so the body can access its inherent
              ability to heal itself.
            </p>
          </Typography>
          <Divider variant="middle" />
          <Typography variant="h6" className="faq-text-mobile">
            <b>How does energy healing work? </b>
          </Typography>
          <Typography className="faq-text-mobile">
            <p>
              The human body is surrounded internally and externally by an
              electromagnetic energy field. This energy field flows through and
              permeates all living things - this is something well known in
              science and physics. In eastern medicine, it has long been
              understood that critical information is held within the energy
              field relating to a person's health. From an energy healer's
              standpoint, all health issues are interconnected through three
              realities: mind, body, and spirit. These three levels all work
              together and influence each other for your health and vitality.
              When in harmony they will operate in health - but, they will
              create disease when out of sync.
            </p>
          </Typography>
          <Divider variant="middle" />
          <Typography variant="h6" className="faq-text-mobile">
            <b>How can meditation help me? </b>
          </Typography>
          <Typography className="faq-text-mobile">
            <p>
              Those who want to meditate can choose among a wide range of
              practices. What they have in common is a narrowing of focus that
              shuts out the external world and stills the body. There are
              numerous studies done on the benefits of meditation. In a study
              involving Charles L. Raison, MD, clinical director of the
              Mind-Body Program at Emory University School of Medicine in
              Atlanta, meditation was found to improve both physical and
              emotional responses to stress. In the study, people who meditated
              regularly for six weeks showed less activation of their immune
              systems and less emotional distress when they were put in a
              stressful situation. Stress reduction could be the key to
              mediation's beneficial effect on health. More studies have shown
              improvement for a host of maladies, including fibromyalgia and
              psoriasis in patients who meditate. Science hasn't yet connected
              all the dots between the meditating brain and the immune system.
              But a University of Wisconsin study saw increased electrical
              activity in regions of the left frontal lobe after eight weeks of
              training in meditation. This area tends to be more active in
              optimistic people.
            </p>
          </Typography>
          <Divider variant="middle" />
          <a
            href="https://chopra.com/articles/how-meditation-can-help-anxiety#sm.00001ev0vhl7p3en6xqti8zwwpw50"
            className="faq-link-text"
          >
            Click here for more information from Deepak Chopra
          </a>
          <Divider variant="middle" />
          <img className="faq-image" src={Chakra} alt="7 Chakras" />
        </div>
      )}
    </>
  );
}

export default FAQ;
