import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import './EmailForm.css'

function ContactForm() {
  const [state, handleSubmit] = useForm("xoqzjopj");
  if (state.succeeded) {
    return <p className="submission-true">Thanks for reaching out!</p>;
  }
  return (
    <form className="form" onSubmit={handleSubmit}>
      
      <label htmlFor="name">Name</label>
      <input className="form-elements" id="name" name="name" required />
      <ValidationError prefix="Name" field="name" errors={state.errors} />


      <label htmlFor="email">Email Address</label>
      <input className="form-elements" id="email" type="email" name="email" required />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      <label htmlFor="subject">Subject</label>
      <input className="form-elements" id="subject" name="subject" required />
      <ValidationError prefix="Subject" field="subject" errors={state.errors} />


      <label htmlFor="message">Message</label>
      <textarea className="form-elements" id="message" name="message" required />
      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <button className="form-elements" type="submit"  value="Yes" id="popUpYes" disabled={state.submitting}>
        Submit
      </button>


    </form>
  );
}

export default ContactForm;
