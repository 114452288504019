import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Link, Divider } from "@mui/material/";
import Services from "../Services";
import "./Meditation.css";

function Meditation() {
  return (
    <div>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="I am formally trained in Transcendental Meditation but practice other forms as well. 
          Meditation is finally getting the recognition it deserves. 
          It not only dissolves stress but decreases heart rate, lowers blood pressure, and will benefit your life in a myriad of ways. 
          It is a simple exercise that is very easy to do. Don't hesitate to learn more because it seems too Eastern or foreign to you. 
          This practice is now mainstream and too good for you to pass up. Try it today!"
          content="Meditation"
        />
      </Helmet>
      <div className="meditation-master-div">
        <Typography variant="h6" className="meditation-title-text">
          Meditation
        </Typography>
        <div className="meditation-divider-reducer">
          <Divider variant="middle" />
        </div>
        <Typography
          className="meditation-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <p>
            I am formally trained in Transcendental Meditation but practice
            other forms as well. Meditation is finally getting the recognition
            it deserves. It not only dissolves stress but decreases heart rate,
            lowers blood pressure, and will benefit your life in a myriad of
            ways. It is a simple exercise that is very easy to do. Don't
            hesitate to learn more because it seems too Eastern or foreign to
            you. This practice is now mainstream and too good for you to pass
            up. Try it today!
          </p>
          <br />
          <p>One on One session $50.00</p>
          <p>Group session $15.00 per person</p>
          <br />
          <Link
            href="/ReachOut"
            underline="none"
            className="meditation-link-text"
          >
            {"Click here to schedule a session"}
          </Link>
        </Typography>
        <div className="meditation-divider-reducer">
          <Divider variant="middle" />
        </div>
        <Services />
      </div>
    </div>
  );
}

export default Meditation;
