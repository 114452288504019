import React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Box,
  Stack,
  Paper,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
} from "@mui/material/";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { styled } from "@mui/material/styles";
import WelcomeWoman from "../../assets/WelcomeWoman.jpeg";
import JacquelineWelcome from "../../assets/JacquelineWelcome.jpeg";
import "./Welcome.css";

function Welcome() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#f7f6f2",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#000000",
  }));

  return (
    <div>
      <Helmet>
          <title>Jacqueline Marie Healing</title>
          <meta name="Reiki and Sacred Childbirth with Reiki is a hands-on Japanese technique 
          for stress reduction and relaxation that also promotes healing. 
          It is administered through the hands of the practitioner and uses life force energy that flows through us and is what causes us to be alive. 
          If one's life force energy is low, then we are more likely to get sick or feel stress, and if it is high, we are more capable of being happy and healthy."
          content="Home Page" />
      </Helmet>
      <div className="welcome-image-container-text">
        <img className="welcome-image" src={WelcomeWoman} alt="Welcome" />
        <div class="welcome-centered-image-text">WELCOME</div>
        <div class="welcome-centered-image-underline">_</div>
      </div>
      <container>
        <Typography
          className="welcome-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <p>
            My name is Jacqueline Mathusek, and I am a Reiki Master Teacher. I
            study and practice different healing modalities and have come to
            realize that what I have learned is meant to be shared.
          </p>
        </Typography>

        <Divider variant="middle" />

        <Typography
          className="welcome-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <br />
          <p>
            We live in a multi-sensory and energetic environment, full of
            stresses and barriers to living our lives healthy and at our full
            potential. Whether you are in the corporate world and experiencing
            over-work stress and exhaustion, doing physical work and
            experiencing aches and pains, or you need to learn how to deal with
            emotional distress from the everyday hardships in life - I can help
            you.
          </p>
          <br />
          <p>
            I have found ways to deal with the stresses that life throws at us.
            The methods I have learned help clear your energy field while
            reducing stress. These practices calm the mind and body, lessening
            disease and illness - and reminds us to take care of ourselves so
            that we may love and take care of others.
          </p>
          <br />
          <p>
            The results of healing and releasing these filters and blocks can
            genuinely have a profound effect in every area of your life.
            Experience a transformation in relationships, physical healing, and
            how you perform in your personal and business life.
          </p>
        </Typography>

        <a href="/ReachOut" className="welcome-link-text">
          Receive a free recorded meditation
        </a>

        <br />
        <Divider variant="middle" />
        <Typography
          className="welcome-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <br />
          <p>
            You are entering the first stage of a new you. Every session is as
            unique as each individual and becomes a building block for personal
            transformation.
          </p>

          <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
              <Item>
                <List>
                  <div className="welcome-bulleted-list">
                    <ListItemIcon>
                      <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                    </ListItemIcon>
                    <ListItem disablePadding>
                      <ListItemText primary="Do you need a simple solution to stress and anxiety relief?" />
                    </ListItem>
                  </div>
                  <div className="welcome-bulleted-list">
                    <ListItemIcon>
                      <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                    </ListItemIcon>
                    <ListItem disablePadding>
                      <ListItemText primary="Are you tired of listening to the news and headlines and want to know a technique to calm yourself?" />
                    </ListItem>
                  </div>
                  <div className="welcome-bulleted-list">
                    <ListItemIcon>
                      <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                    </ListItemIcon>
                    <ListItem disablePadding>
                      <ListItemText primary="Have you always wanted to learn how to meditate and experience the benefits?" />
                    </ListItem>
                  </div>
                  <div className="welcome-bulleted-list">
                    <ListItemIcon>
                      <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                    </ListItemIcon>
                    <ListItem disablePadding>
                      <ListItemText primary="Have you gone through traumas such as abuse, divorce, or losing a loved one?" />
                    </ListItem>
                  </div>
                  <div className="welcome-bulleted-list">
                    <ListItemIcon>
                      <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                    </ListItemIcon>
                    <ListItem disablePadding>
                      <ListItemText primary="Are you looking to work on your emotional self - to grow and learn how to live your life joyfully and without fears that block your progress?" />
                    </ListItem>
                  </div>
                  <div className="welcome-bulleted-list">
                    <ListItemIcon>
                      <RadioButtonUncheckedIcon sx={{ fontSize: 10 }} />
                    </ListItemIcon>
                    <ListItem disablePadding>
                      <ListItemText primary="Do you feel like you have tried everything to heal your condition and remain unhealed and frustrated?" />
                    </ListItem>
                  </div>
                </List>
              </Item>
            </Stack>
          </Box>
          <br />
          <p>
            If you answered yes to any of the questions above, energy healing
            may be what you are looking for. I&#39;ll provide the guidance you
            need to reduce stress, increase relaxation and energy while
            strengthening the immune system. <br />
            <br /> Accelerate recovery time from disease and sickness, release
            the blocks that distract you from love&#39;s presence, and become
            the love that you desire <br /> <br />
            <b>you attract what you radiate!</b>
          </p>
        </Typography>
      </container>

      <img className="welcome-image" src={JacquelineWelcome} alt="Jacqueline" />

      <a href="MyJourney" className="welcome-link-text">
        My journey of study and experience
      </a>
      <br />
      <Divider variant="middle" />

      <container className="welcome-testimonials-container">
        <Typography
          className="welcome-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <p>
            <b>TESTIMONIALS</b>
            <br />
            <br />
            <i>
              "Getting Reiki from Jacqueline has been extremely beneficial for
              me! After every treatment I was amazed at how centered and calm I
              felt. All my troubles seem to melt away along with aches and pains
              I was experiencing. I am so glad I gave it a try because I didn't
              know what to expect. Thank you!” <br /> - Vicki M.
            </i>
            <br />
            <br />
            <i>
              "I had the opportunity to have a Reiki session with Jacqui. When
              she started at my head I felt a surge of energy which I have never
              experienced before. I felt really relaxed and calm afterward.
              Using light aromatherapy and music added to the experience which
              helped the stress I was feeling. I was also experiencing knee pain
              which was markedly reduced.” <br /> - Jean D.
            </i>
          </p>
        </Typography>
      </container>
    </div>
  );
}

export default Welcome;
