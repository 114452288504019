import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Link, Divider } from "@mui/material/";
import Services from "../Services";
import "./Reiki.css";

function Reiki() {
  return (
    <div>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="Reiki and Sacred Childbirth with Reiki is a hands-on Japanese technique for stress reduction and relaxation that also promotes healing. 
          It is administered through the hands of the practitioner and uses life force energy that flows through us and is what causes us to be alive. 
          If one's life force energy is low, then we are more likely to get sick or feel stress, and if it is high, we are more capable of being happy and healthy."
          content="Reiki"
        />
      </Helmet>
      <div className="reiki-master-div">
        <Typography variant="h6" className="reiki-title-text">
          Reiki
        </Typography>
        <div className="reiki-divider-reducer">
          <Divider variant="middle" />
        </div>
        <Typography
          className="reiki-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <p>
            Reiki and Sacred Childbirth with Reiki is a hands-on Japanese
            technique for stress reduction and relaxation that also promotes
            healing. It is administered through the hands of the practitioner
            and uses life force energy that flows through us and is what causes
            us to be alive. If one's life force energy is low, then we are more
            likely to get sick or feel stress, and if it is high, we are more
            capable of being happy and healthy.
          </p>
          <br />
          <p>
            The word Reiki is made of two Japanese words - Rei, which means
            "God's Wisdom or the Higher Power" and Ki, which is "life force
            energy". Thus Reiki means "spiritually guided life force energy."
          </p>
          <br />
          <p>
            Reiki is just one form of energy healing - a natural ability we all
            have. I work with your energy body, which can be accessed from
            anywhere in the world! When you schedule a session, it is available
            in person or by distance worldwide (available by phone, Skype, and
            WhatsApp).
          </p>
          <br />
          <p>
            Sessions are 60 - 90 minutes. Although one session is powerful, most
            people experience relief or a dramatic shift; real, lasting
            transformation and healing can take time. Getting to the root can
            happen quickly or slowly, depending on the person. I recommend
            making a commitment to ongoing work for more in-depth issues, but
            there is never any obligation.
          </p>
          <br />
          <p>(1) session of Reiki $75.00</p>
          <p>(3) sessions of Reiki $200.00</p>
          <br />
          <div className="reiki-divider-reducer"></div>
          <br />
          <p>
            <b>Sacred Childbirth with Reiki™</b>
          </p>
          <Divider variant="middle" />
          <p>
            Sacred Childbirth with Reiki teaches you how to create an
            effortless, serene, drug-free birth. Relax into the core of your
            being, clearing fear, trauma, and negativity about birth practice
            accessing your body&#39;s knowledge of birth with ease—visualization
            of your dream birth and specific tools for birthing partner
            involvement. The Sacred Childbirth with Reiki program is a
            complement to your prenatal care.
          </p>
          <p>
            1.5 - 2 hour Sacred Childbirth session $100-$150 (3) sessions are
            recommended $325.00
          </p>
          <br />
          <Divider variant="middle" />

          <Link href="/ReachOut" underline="none" className="reiki-link-text">
            {
              "Click here to schedule a Reiki session, Scared Childbirth with Reiki program, or a FREE 15 minute consultation"
            }
          </Link>
        </Typography>
        <div className="reiki-divider-reducer">
          <Divider variant="middle" />
        </div>
        <Services />
      </div>
    </div>
  );
}

export default Reiki;
