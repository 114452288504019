import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Link, Divider } from "@mui/material/";
import Services from "../Services";
import "./WorkingWithChildren.css";

function WorkingWithChildren() {
  return (
    <div>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="I firmly believe that if we start our children off at an early age with an education on how to calm their stresses and anxieties, 
          their life experience will grow exponentially. Today, there is a need for our children to learn how to navigate school, 
          their interactions with other children, social media, and the teen years, which can be incredibly difficult. 
          I offer meditation training and reiki treatments to children of all ages."
          content="Working with Children"
        />
      </Helmet>
      <div className="children-master-div">
        <Typography variant="h6" className="children-title-text">
          Working with Children
        </Typography>
        <div className="children-divider-reducer">
          <Divider variant="middle" />
        </div>
        <Typography
          className="children-text"
          variant="inherit"
          align="center"
          gutterBottom
          paragraph
        >
          <p>
            I firmly believe that if we start our children off at an early age
            with an education on how to calm their stresses and anxieties, their
            life experience will grow exponentially. Today, there is a need for
            our children to learn how to navigate school, their interactions
            with other children, social media, and the teen years, which can be
            incredibly difficult. I offer meditation training and reiki
            treatments to children of all ages.
          </p>
          <br />
          <p>One on One session $65.00 per hour</p>
          <p>Group session $15.00 per child</p>
          <br />
          <p>
            <i>
              Pricing may be dependent on need or ability to pay. I am
              passionate about working with children and will explore payment
              options.
            </i>
          </p>
          <Link
            href="/ReachOut"
            underline="none"
            className="children-link-text"
          >
            {
              "Click here to schedule a session or join the children's meditation group"
            }
          </Link>
        </Typography>
        <div className="children-divider-reducer">
          <Divider variant="middle" />
        </div>
        <Services />
      </div>
    </div>
  );
}

export default WorkingWithChildren;
