import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import ResponsiveAppBar from "./components/ResponsiveAppBar/ResponsiveAppBar";
import Footer from "./components/Footer/Footer";
import Welcome from "./components/Welcome/Welcome";
import MyJourney from "./components/MyJourney/MyJourney";
import Services from "./components/Services/Services";
import ReikiTraining from "./components/ReikiTraining/ReikiTraining";
import FAQ from "./components/FAQ/FAQ";
import ReachOut from "./components/ReachOut/ReachOut";
import Reiki from "./components/Services/Reiki/Reiki";
import Meditation from "./components/Services/Meditation/Meditation";
import WorkingWithChildren from "./components/Services/WorkingWithChildren/WorkingWithChildren";
import "./App.css";

function App() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 870);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 870);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      {isDesktop ? (
        <div className="canvas">
          <ResponsiveAppBar />
          <Router>
            <Routes>
              <Route exact path="/" element={<Welcome />} />
              <Route exact path="/MyJourney" element={<MyJourney />} />
              <Route exact path="/Services" element={<Services />} />
              <Route exact path="/ReikiTraining" element={<ReikiTraining />} />
              <Route exact path="/FAQ's" element={<FAQ />} />
              <Route exact path="/ReachOut" element={<ReachOut />} />
              <Route exact path="/Reiki" element={<Reiki />} />
              <Route exact path="/Meditation" element={<Meditation />} />
              <Route
                exact
                path="/WorkingWithChildren"
                element={<WorkingWithChildren />}
              />
            </Routes>
          </Router>
          <Footer />
        </div>
      ) : (
        <>
          <ResponsiveAppBar />
          <Router>
            <Routes>
              <Route exact path="/" element={<Welcome />} />
              <Route exact path="/MyJourney" element={<MyJourney />} />
              <Route exact path="/Services" element={<Services />} />
              <Route exact path="/ReikiTraining" element={<ReikiTraining />} />
              <Route exact path="/FAQ's" element={<FAQ />} />
              <Route exact path="/ReachOut" element={<ReachOut />} />
              <Route exact path="/Reiki" element={<Reiki />} />
              <Route exact path="/Meditation" element={<Meditation />} />
              <Route
                exact
                path="/WorkingWithChildren"
                element={<WorkingWithChildren />}
              />
            </Routes>
          </Router>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
