import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material/";
import PurpleFlower from "../../assets/PurpleFlower.jpeg";
import ContactForm from "./EmailForm";
import "./ReachOut.css";

function ReachOut() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 870);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 870);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="I've tried to incorporate as much information as possible on my website and the FAQ's page to help you get started on your healing journey.  
          But I'm sure I may have missed some information that you might need to know.  
          Feel free to ask those questions here or contact me for a free, no-obligation, 15-minute consultation. I will get back to you as soon as possible."
          content="Contact Page"
        />
      </Helmet>
      {isDesktop ? (
        <>
          <div className="reach-out-image-container-text">
            <img
              className="reach-out-image"
              src={PurpleFlower}
              alt="Reach Out"
            />
            <div class="reach-out-centered-image-text">REACH OUT</div>
            <div class="reach-out-centered-image-underline">_</div>
          </div>
          <div className="reach-out-column">
            <div className="reach-out-left">
              <Typography variant="h6" align="center">
                <p>How can I help?</p>
              </Typography>
              <Typography
                className="welcome-text"
                variant="inherit"
                align="center"
                gutterBottom
                paragraph
              >
                <p>
                  I've tried to incorporate as much information as possible on
                  my website and the FAQ's page to help you get started on your
                  healing journey. But I'm sure I may have missed some
                  information that you might need to know. Feel free to ask
                  those questions here or contact me for a free, no-obligation,
                  15-minute consultation. I will get back to you as soon as
                  possible.
                </p>
              </Typography>
            </div>

            <div className="reach-out-right">
              <ContactForm />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="reach-out-image-container-text">
            <img
              className="reach-out-image"
              src={PurpleFlower}
              alt="Reach Out"
            />
            <div class="reach-out-centered-image-text">REACH OUT</div>
          </div>
          <Typography variant="h6" align="center">
            <p>How can I help?</p>
          </Typography>
          <Typography
            className="welcome-text"
            variant="inherit"
            align="center"
            gutterBottom
            paragraph
          >
            <p>
              I've tried to incorporate as much information as possible on my
              website and the FAQ's page to help you get started on your healing
              journey. But I'm sure I may have missed some information that you
              might need to know. Feel free to ask those questions here or
              contact me for a free, no-obligation, 15-minute consultation. I
              will get back to you as soon as possible.
            </p>
            <ContactForm />
          </Typography>
        </>
      )}
    </div>
  );
}

export default ReachOut;
