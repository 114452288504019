import React from "react";
import { useState, useEffect } from "react";
import { Link, Divider, Box, Typography } from "@mui/material/";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import "./Footer.css";

function Footer() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 870);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 870);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <Divider variant="middle" />
      {isDesktop ? (
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
          }}
        >
          <div className="footer-div-css">
            <Typography className="footer-text" variant="inherit" gutterBottom>
              <p>jacquelinemariehealing.com 2021©</p>
            </Typography>
            <div>
              <Link
                href="https://www.facebook.com/JacquelineMarieHealing/"
                target="_blank"
              >
                <FacebookRoundedIcon color="action" fontSize="large" />
              </Link>
              <Link
                href="mailto:jacquelinemariehealing@gmail.com"
                target="_blank"
              >
                <EmailRoundedIcon color="action" fontSize="large" />
              </Link>
            </div>
          </div>
        </Box>
      ) : (
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
          }}
        >
          <div>
            <Typography className="footer-text" variant="inherit" gutterBottom>
              <p>jacquelinemariehealing.com 2021©</p>
            </Typography>

            <div className="footer-div-mobile-css">
              <Link
                href="https://www.facebook.com/JacquelineMarieHealing/"
                target="_blank"
              >
                <FacebookRoundedIcon color="action" fontSize="large" />
              </Link>

              <Link
                href="mailto:jacquelinemariehealing@gmail.com"
                target="_blank"
              >
                <EmailRoundedIcon color="action" fontSize="large" />
              </Link>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}
export default Footer;
