import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Link, Divider } from "@mui/material/";
import ReikiStages from "../../assets/ReikiStages.jpeg";
import "./ReikiTraining.css";

function ReikiTraining() {
  return (
    <div>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="I offer Reiki and Meditation services to adults and children. As a Reiki Master, 
          I provide training for Reiki level 1, level 2, and Reiki level 3 which is the Master/Teacher level of the Usui Reiki healing system"
          content="Reiki Training"
        />
      </Helmet>
      <div className="training-master-div">
        <div className="training-div-padding">
          <Typography variant="h6" align="center">
            <b>Reiki Level I</b>
          </Typography>
          <Typography className="training-text" align="center">
            <p>
              This is a one-day certification class teaching Level I of the Usui
              Reiki healing system. With this level, you will begin deep healing
              that you will use on your journey and share with others. The
              attunement also includes guided practice sessions to gain
              experience and confidence with the energy. At the end of the class
              you will administer hands on Reiki treatments to yourself and
              others and have a beginning understanding of the energy body.{" "}
              <br />
              <br /> Training Fee: $165.00 Fee includes a class training manual,
              Reiki I certificate and refreshments. Minimum class size is 2
              students, max is 10.
            </p>
          </Typography>
          <Link
            href="/ReachOut"
            underline="none"
            className="training-link-text"
          >
            {"Click here to register or learn more information"}
          </Link>
        </div>
        <Divider variant="middle" />
        <div className="training-div-padding">
          <Typography variant="h6" align="center">
            <b>Reiki Level II</b>
          </Typography>
          <Typography className="training-text" align="center">
            <p>
              This is a one-day certification class teaching Level II, which is
              an expansion of Reiki I. Level II provides the use of symbols that
              increase Reiki energy flow, provide emotional and mental healing,
              and empower sending healing across both time and space. This class
              introduces new healing techniques and will help you further
              develop your intuitive skills. Reiki II will increase your
              understanding of how energy and consciousness work collectively.
              At the end of this class, you will be able to use the Level II
              Reiki symbols and send distant healings. <br />
              <br /> Training Fee $165.00 Fee includes class training manual,
              Reiki 2 certificate and refreshments. Minimum class is 2 students,
              max is 10. <br />
              <br /> <b>Level II Prerequisites:</b> Reiki Level I, if trained by
              another teacher please provide certificate
            </p>
          </Typography>
          <Link
            href="/ReachOut"
            underline="none"
            className="training-link-text"
          >
            {"Click here to register or learn more information"}
          </Link>
        </div>
        <Divider variant="middle" />
        <div className="training-div-padding">
          <Typography variant="h6" align="center">
            <b>Reiki Level III</b>
          </Typography>
          <Typography className="training-text" align="center">
            <p>
              This class takes you to the Master/Teacher level of the Usui Reiki
              healing system. You will receive the Master attunement during this
              class, learn the master symbol and two additional Tibetan symbols.
              You will also learn how to pass attunements to others and teach
              your own classes. <br />
              <br /> An apprenticeship is part of the Master/Teacher
              certification. To help you gain comfort with the new energy and
              techniques, you will perform 20 sessions on your own as a Master
              Practitioner following the class. The apprenticeship then includes
              participating in a Reiki I and II class as an assistant to further
              your training in giving attunements and teaching. <br />
              <br /> Training Fee $475.00 Fee includes Master training manual,
              Reiki Master Practitioner certificate, Master/Teacher certificate
              upon completion of apprenticeship and refreshments at all classes.
              Minimum class size is 1 student and max is 10. <br />
              <br /> <b>Master Prerequisites: </b> Reiki Level I &#38; II. If
              trained from another teacher please provide certificates.
            </p>
          </Typography>
          <Link
            href="/ReachOut"
            underline="none"
            className="training-link-text"
          >
            {"Click here to register or learn more information"}
          </Link>
          <img
            className="training-image"
            src={ReikiStages}
            alt="Reiki Stages"
          />
        </div>
      </div>
    </div>
  );
}

export default ReikiTraining;
