import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Link } from "@mui/material/";
import EnergyHands from "../../assets/EnergyHands.jpeg";
import Meditating from "../../assets/Meditating.jpeg";
import Child from "../../assets/Child.jpeg";
import "./Services.css";

function TextAsLink() {
  var link = (
    <Link href="/FAQ's" underline="none" className="services-link-text">
      {"FAQ's page"}
    </Link>
  );

  return (
    <p className="services-link-sentance">
      Click on a service or visit my&nbsp;{link}&nbsp;to learn more about how my
      services can help you
    </p>
  );
}

function Services() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 870);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 870);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <Helmet>
        <title>Jacqueline Marie Healing</title>
        <meta
          name="I offer Reiki and Meditation services to adults and children - right here in the Milwaukee area!"
          content="Services"
        />
      </Helmet>
      <div className="services-master-div">
        {isDesktop ? (
          <>
            <div className="services-div-css">
              <div className="services-reiki">
                <Link href="/Reiki">
                  <img
                    className="services-image"
                    src={EnergyHands}
                    alt="Reiki"
                  />
                </Link>
                <Link
                  href="/Reiki"
                  underline="none"
                  className="services-link-sentance"
                >
                  {"Reiki"}
                </Link>
              </div>
              <div className="services-meditation">
                <Link href="/Meditation">
                  <img
                    className="services-image"
                    src={Meditating}
                    alt="Meditating"
                  />
                </Link>
                <Link
                  href="/Meditation"
                  underline="none"
                  className="services-link-sentance"
                >
                  {"Meditation"}
                </Link>
              </div>
              <div className="services-children">
                <Link href="/WorkingWithChildren">
                  <img
                    className="services-image"
                    src={Child}
                    alt="Working with Children"
                  />
                </Link>
                <Link
                  href="/WorkingWithChildren"
                  underline="none"
                  className="services-link-sentance"
                >
                  {"Working with Children"}
                </Link>
              </div>
            </div>
            <TextAsLink />
          </>
        ) : (
          <>
            <div className="services-div-css-mobile">
              <div className="services-reiki">
                <Link href="/Reiki">
                  <img
                    className="services-image"
                    src={EnergyHands}
                    alt="Reiki"
                  />
                </Link>
                <Link
                  href="/Reiki"
                  underline="none"
                  className="services-link-sentance"
                >
                  {"Reiki"}
                </Link>
              </div>
              <div className="services-meditation">
                <Link href="/Meditation">
                  <img
                    className="services-image"
                    src={Meditating}
                    alt="Meditating"
                  />
                </Link>
                <Link
                  href="/Meditation"
                  underline="none"
                  className="services-link-sentance"
                >
                  {"Meditation"}
                </Link>
              </div>
              <div className="services-children">
                <Link href="/WorkingWithChildren">
                  <img
                    className="services-image"
                    src={Child}
                    alt="Working with Children"
                  />
                </Link>
                <Link
                  href="/WorkingWithChildren"
                  underline="none"
                  className="services-link-sentance"
                >
                  {"Working with Children"}
                </Link>
              </div>
            </div>
            <Link
              href="/FAQ's"
              underline="none"
              className="services-link-text-mobile"
            >
              {
                "Click on a service or visit my FAQ's page to learn more about how my services can help you"
              }
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default Services;
